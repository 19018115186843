import React, { useRef, useState } from 'react';

import { Tour, TourProps } from 'antd';
import { useGetUserLeaguesQuery } from 'api/leagues';

const IntroTour = () => {
  const { data: userLeagues } = useGetUserLeaguesQuery();

  const [open, setOpen] = useState<boolean>(userLeagues?.length === 0);
  const searchRef = useRef(null);
  const userLeaguesRef = useRef(null);
  const steps: TourProps['steps'] = [
    {
      title: 'Dodaj swoją pierwszą ligę',
      description: 'Wybierz ligę, w której chcesz brać udział.',
      target: () => searchRef.current,
      nextButtonProps: { children: 'Dalej' },
    },
    {
      title: 'Tutaj znajdziesz swoje ligi',
      description: 'Możesz stąd przejść bezpośrednio do obstawiania wybranej ligi.',
      target: () => userLeaguesRef.current,
      nextButtonProps: { children: 'Koniec' },
      prevButtonProps: { children: 'Wstecz' },
    },
  ];

  return (
    <Tour disabledInteraction open={open} onClose={() => setOpen(false)} steps={steps} />
  );
};

export default IntroTour;
