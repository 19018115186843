import { ROUTES } from 'constants/routes';

import React from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Badge } from 'antd';
import { useGetMeQuery } from 'api/users';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectInvitations } from 'store/invitationsSlice';

import Avatar from 'components/atoms/Avatar';

import 'theme/sanitize.css';
import 'theme/fonts.css';

import { dropDownMenu } from './config';

const AvatarDropdown: React.FC = () => {
  const invitations = useSelector(selectInvitations);
  const navigate = useNavigate();

  const { t } = useTranslation('translation', {
    keyPrefix: 'menu',
  });
  const { data } = useGetMeQuery();

  const activeInvitations =
    invitations?.filter((invitation) => {
      return invitation.status === 'pending';
    })?.length || 0;

  const dropdownMenu = [
    ...(activeInvitations
      ? [
          {
            key: 'invitations',
            label: (
              <>
                Zaproszenia <Badge count={activeInvitations} />
              </>
            ),
            onClick: () => navigate(ROUTES.GROUPS),
          },
        ]
      : []),
    ...dropDownMenu.map(({ label, key, to }) => ({
      key,
      'data-testid': key,
      label: t(label),
      onClick: () => navigate(to),
    })),
  ];

  return (
    <Dropdown
      menu={{
        items: dropdownMenu,
      }}
      placement="bottomLeft"
    >
      <span data-testid={'userAvatar'}>
        {/* for some span wrapper is needed for dropdown to work*/}

        <Badge count={activeInvitations}>
          <Avatar username={data?.username || ''} size={40} src={data?.avatar} />
        </Badge>
        <CaretDownOutlined />
      </span>
    </Dropdown>
  );
};

export default AvatarDropdown;
