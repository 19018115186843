import { useTranslation } from 'react-i18next';

export const useValidationRules = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'formErrors' });

  const required = {
    value: true,
    message: t('required'),
  };

  return {
    email: {
      required,
      pattern: {
        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: t('email'),
      },
      minLength: {
        value: 6,
        message: t('minLength', { n: 6 }),
      },
      maxLength: {
        value: 100,
        message: t('maxLength', { n: 100 }),
      },
    },
    location: {
      minLength: {
        value: 3,
        message: t('minLength', { n: 3 }),
      },
      maxLength: {
        // longest city name in poland is 30 characters
        value: 30,
        message: t('maxLength', { n: 30 }),
      },
    },
    search: {
      minLength: {
        value: 3,
        message: t('minLength', { n: 3 }),
      },
      maxLength: {
        value: 150,
        message: t('maxLength', { n: 150 }),
      },
    },
    loginPassword: {
      required,
      minLength: {
        value: 6,
        message: t('minLength', { n: 6 }),
      },
      maxLength: {
        value: 50,
        message: t('maxLength', { n: 50 }),
      },
    },
    username: {
      required,
      minLength: {
        value: 6,
        message: t('minLength', { n: 6 }),
      },
      maxLength: {
        value: 50,
        message: t('maxLength', { n: 50 }),
      },
      pattern: {
        message: 'Nazwa użytkownika może zawierać tylko litery i cyfry',
        value: /^[a-zA-Z0-9]*$/,
      },
    },
    groupName: {
      required,
      minLength: {
        value: 6,
        message: t('minLength', { n: 6 }),
      },
      maxLength: {
        value: 50,
        message: t('maxLength', { n: 50 }),
      },
    },
    groupDescription: {
      required,
      minLength: {
        value: 6,
        message: t('minLength', { n: 6 }),
      },
      maxLength: {
        value: 128,
        message: t('maxLength', { n: 128 }),
      },
    },
    groupLeague: {
      required,
    },
    groupUsers: {
      required,
    },
    registerPassword: {
      required,
      minLength: {
        value: 8,
        message: t('minLength', { n: 8 }),
      },
      maxLength: {
        value: 50,
        message: t('maxLength', { n: 50 }),
      },
      pattern: {
        value: /^(?=.*[a-zA-Z]).+$/,
        message: 'Hasło musi zawierać co najmniej jedną literę',
      },
    },
    commentTitle: {
      required,
      minLength: {
        value: 3,
        message: t('minLength', { n: 3 }),
      },
      maxLength: {
        value: 100,
        message: t('maxLength', { n: 100 }),
      },
    },
    commentContent: {
      required,
      minLength: {
        value: 3,
        message: t('minLength', { n: 3 }),
      },
      maxLength: {
        value: 500,
        message: t('maxLength', { n: 500 }),
      },
    },
  };
};
