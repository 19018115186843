import React, { useEffect } from 'react';

import { useRefreshTokenQuery } from 'api/auth';
import { RouterProvider } from 'react-router-dom';

import { hasToken } from './api/utils';
import { router } from './routing';
import { GeneralThemeProvider } from './theme/GeneralThemeProvider';
import { initGA, logPageView } from './utils/analytics';

// set interval to 4 minutes, access token is valid for 5 minutes
// refreshToken is used to get new access token
const interval = 4 * 60 * 1000;
const TRACKING_ID = 'G-NM1NR39YJV';

initGA(TRACKING_ID);

function App() {
  useEffect(() => {
    logPageView();
  }, []);

  useRefreshTokenQuery(undefined, {
    skip: !hasToken(),
    pollingInterval: interval,
  });

  return (
    <GeneralThemeProvider>
      <RouterProvider router={router} />
    </GeneralThemeProvider>
  );
}

export default App;
