import React from 'react';

import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

interface TableTitleProps {
  title: string;
  subtitle?: string;
}

export const TableTitle = ({ title, subtitle }: TableTitleProps) => {
  return (
    <>
      <Title level={3}>{title}</Title>
      {subtitle && <Paragraph>{subtitle}</Paragraph>}
    </>
  );
};
