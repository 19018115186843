import { QUERY_KEYS } from 'constants/routes';

import React, { useCallback, useLayoutEffect } from 'react';

import { TabsProps, Collapse, Tabs, Grid } from 'antd';
import { useGetUserAllGroupsQuery } from 'api/groups';
import { useSearchParams } from 'react-router-dom';
import { GroupsThemeProvider } from 'theme/GroupsThemeProvider';

import NoGroups from 'components/atoms/NoGroups';
import { Spinner } from 'components/atoms/Spinner';
import { TabWrapper } from 'components/templates/DashboardLayout/styles';

const { useBreakpoint } = Grid;

export interface ContentComponentProps {
  leagueId: string;
  groupId: string;
  title: string;
  subtitle: string;
}

interface GroupDataNavigationProps {
  ContentComponent: React.FC<ContentComponentProps>;
}

const GroupDataNavigation: React.FC<GroupDataNavigationProps> = ({
  ContentComponent,
}) => {
  const { data: groups, isLoading } = useGetUserAllGroupsQuery();

  const screens = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupIdParam = searchParams.get(QUERY_KEYS.GROUP_ID);

  const items: TabsProps['items'] = groups?.map((userGroup) => {
    return {
      key: String(userGroup.id),
      label: <TabWrapper>{userGroup.name}</TabWrapper>,
      children: (
        <ContentComponent
          groupId={String(userGroup.id)}
          leagueId={String(userGroup.league.id)}
          title={userGroup.name}
          subtitle={`${userGroup.league.title}. ${userGroup.description}`}
        />
      ),
    };
  });

  const handleSetSearchParams = useCallback(
    (key: string) => {
      searchParams.set(QUERY_KEYS.GROUP_ID, key);
      setSearchParams(searchParams, { replace: true });
    },
    [setSearchParams],
  );

  useLayoutEffect(() => {
    if (!items) {
      return;
    }

    if (!groupIdParam && !screens.xs) {
      handleSetSearchParams(items[0]?.key);
    }
  }, [handleSetSearchParams, items, groupIdParam, screens.xs]);

  const commonProps = {
    items,
    destroyInactiveTabPane: true,
  };

  const onChange = (key: string) => {
    handleSetSearchParams(key);
  };

  if (groups && groups.length === 0) {
    return <NoGroups />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GroupsThemeProvider>
      {screens.xs ? (
        <Collapse defaultActiveKey={Number(groupIdParam)} {...commonProps} />
      ) : (
        <Tabs
          onChange={onChange}
          defaultActiveKey={String(groupIdParam)}
          // @ts-expect-error Types require activeKey to be a string but in reality it's working with number not with the string
          activeKey={groupIdParam}
          tabPosition={screens.lg ? 'left' : 'top'}
          {...commonProps}
        />
      )}{' '}
    </GroupsThemeProvider>
  );
};

export default GroupDataNavigation;
