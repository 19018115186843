import React from 'react';

import {
  useFetchGroupLeagueWeekendMatchesToBetQuery,
  useSendGroupBetMutation,
} from 'api/matches';
import { FieldValues } from 'react-hook-form';

import { ContentComponentProps } from 'components/molecules/GroupDataNavigation/GroupDataNavigation';

import BetTable from './BetTable';

export const GroupLeagueBet: React.FC<ContentComponentProps> = ({
  leagueId,
  groupId,
  title,
  subtitle,
}) => {
  const { data, isLoading, error } = useFetchGroupLeagueWeekendMatchesToBetQuery({
    leagueId,
    groupId,
  });

  const weekNumber = data?.week_number || 0;
  const matchWeek: number = data?.match_week || 0;

  const [sendBet, { isLoading: isSendBetLoading, isError: isSendBetError }] =
    useSendGroupBetMutation();

  const handleBetSend = async (data: FieldValues) => {
    await sendBet({
      group_id: groupId,
      match_week: matchWeek,
      week_number: weekNumber,
      matches: Object.values(data?.matches),
    }).then();
  };

  return (
    <BetTable
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      isSendBetLoading={isSendBetLoading}
      handleBetSend={handleBetSend}
      isSendBetError={isSendBetError}
      error={error}
      data={data}
    />
  );
};
