import { QUERY_KEYS, ROUTES } from 'constants/routes';

import React from 'react';

import { TwitterShareButton, XIcon } from 'react-share';
import styled from 'styled-components';

export const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SocialIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  flex-shrink: 0;
`;

export const SocialSharing = ({ id }: { id: string }) => {
  return (
    <SocialWrapper>
      Podziel się w social mediach!
      <TwitterShareButton
        url={`${window.location.origin}${ROUTES.PUBLIC_RANKINGS}?${QUERY_KEYS.LEAGUE_ID}=${id}`}
      >
        <SocialIconWrapper>
          <XIcon size={32} round />
        </SocialIconWrapper>
      </TwitterShareButton>
    </SocialWrapper>
  );
};
