import React from 'react';

import { Alert, Empty, Table, TableColumnsType, Typography } from 'antd';
import { Match } from 'api/matches';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import { Spinner } from 'components/atoms/Spinner';
import { RowWrapper, TableWrapper } from 'components/atoms/Table';
import TeamImage from 'components/atoms/TeamImage';

import { TableTitle } from '../TableTitle';

import {
  TableHeaderWrapper,
  BetWrapper,
  ResultWrapper,
  TeamNameWrapper,
  Score,
  Bet,
  ScoreWrapper,
  ScoreSum,
  NameWrapper,
} from './styles';
import { getTotalScore } from './utils';

interface DataType {
  key: React.Key;
  hostName: string;
  hostImage: string;
  hostGoals: string;
  hostBetGoals: string;
  guestName: string;
  guestImage: string;
  guestGoals: string;
  guestBetGoals: string;
  score: number | string;
}

const { Text } = Typography;

const columns: TableColumnsType<DataType> = [
  {
    title: 'Gospodarz - Gość',
    render: (record: DataType) => (
      <>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.hostImage} />
            {record.hostName}
          </NameWrapper>
          <ResultWrapper>
            {record.hostGoals} <BetWrapper> {record.hostBetGoals} </BetWrapper>
          </ResultWrapper>
        </RowWrapper>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.guestImage} />
            {record.guestName}
          </NameWrapper>
          <ResultWrapper>
            {record.guestGoals} <BetWrapper> {record.guestBetGoals} </BetWrapper>
          </ResultWrapper>
        </RowWrapper>
        <Score>
          <span>Zdobyte punkty: </span>{' '}
          <ScoreWrapper $highlighted={Number(record?.score) > 0}>
            {record.score}
          </ScoreWrapper>
        </Score>
      </>
    ),
    responsive: ['xs'],
  },
  {
    title: 'Gospodarz',
    align: 'right',
    responsive: ['sm'],
    render: (record: DataType) => (
      <TeamNameWrapper $bold={Number(record.hostGoals) > Number(record.guestGoals)}>
        {record.hostName} <TeamImage margin={'0 0 0 8px'} src={record.hostImage} />
      </TeamNameWrapper>
    ),
  },
  {
    title: 'Rezultat',
    align: 'center',
    render: (record: DataType) => `${record.hostGoals} : ${record.guestGoals}`,
    responsive: ['sm'],
  },
  {
    title: 'Typ',
    render: (record: DataType) => (
      <Bet>
        {record.hostBetGoals} : {record.guestBetGoals}
      </Bet>
    ),
    align: 'center',
    responsive: ['sm'],
  },
  {
    title: 'Zdobyte punkty',
    dataIndex: 'score',
    render: (score: DataType['score']) => (
      <ScoreWrapper $highlighted={Number(score) > 0}>{score}</ScoreWrapper>
    ),
    align: 'center',
    responsive: ['sm'],
  },
  {
    title: 'Gość',
    align: 'left',
    responsive: ['sm'],
    render: (record: DataType) => (
      <TeamNameWrapper $bold={Number(record.guestGoals) > Number(record.hostGoals)}>
        <TeamImage src={record.guestImage} />
        {record.guestName}
      </TeamNameWrapper>
    ),
  },
];

interface LeagueResultsProps {
  matches: Match[];
  date_start: string;
  date_end: string;
  title?: string;
  subtitle?: string;
  isLoading: boolean;
  hasError: boolean;
  tableHeader?: React.ReactNode;
}

export const ResultsTable: React.FC<LeagueResultsProps> = ({
  matches,
  date_start,
  date_end,
  isLoading,
  hasError,
  title,
  subtitle,
  tableHeader,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'results',
  });

  //total score for currentWeek based on bet.score
  const totalScore = getTotalScore(matches);

  if (isLoading) {
    return <Spinner />;
  }

  if (hasError) {
    return (
      <Alert
        message={t('errorTitle')}
        description={t('errorDescription')}
        type="error"
        showIcon
      />
    );
  }

  const tableData: DataType[] = matches?.map((match) => {
    return {
      key: match.id,

      hostName: match.host.name,
      hostImage: match.host.image,
      hostGoals: `${match.host_goals ?? '-'}`,
      hostBetGoals: `${match.bet[0]?.host_goals ?? '-'}`,

      guestName: match.guest.name,
      guestImage: match.guest.image,
      guestGoals: `${match.guest_goals ?? '-'}`,
      guestBetGoals: `${match.bet[0]?.guest_goals ?? '-'}`,

      score: `${match.bet[0]?.score ?? '-'}`,
    };
  });

  return (
    <TableWrapper>
      <Table
        size="middle"
        locale={{
          emptyText: <Empty description={'Brak danych do wyświetlenia'} />,
        }}
        style={{ width: '100%' }}
        title={() => (
          <>
            {title && <TableTitle title={title} subtitle={subtitle} />}
            {tableHeader && <TableHeaderWrapper>{tableHeader}</TableHeaderWrapper>}
            <Text>
              {t('matchesDate')} {formatDate(date_start)} - {formatDate(date_end)}
            </Text>
          </>
        )}
        footer={() => (
          <ScoreSum>
            {t('roundTotalScore')} {totalScore}
          </ScoreSum>
        )}
        pagination={false}
        columns={columns}
        dataSource={tableData}
      />
    </TableWrapper>
  );
};

export default ResultsTable;
