import { ROUTES } from 'constants/routes';

import React from 'react';

import Icon from '@ant-design/icons';
import { Button, Divider, Flex } from 'antd';
import { ReactComponent as PeopleSvg } from 'assets/icons/main-menu/people.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CardTitle from 'components/atoms/CardTitle';
import { StyledCard } from 'components/atoms/styles';

import { Invitations } from '../../Groups/Invitations';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Groups = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'group',
  });
  const navigate = useNavigate();

  return (
    <StyledCard
      title={
        <Flex justify="space-between" align="center">
          <CardTitle>
            {t('title')} <Icon component={PeopleSvg} />
          </CardTitle>
        </Flex>
      }
    >
      <Invitations />
      <Divider />
      <Button variant="solid" color="cyan" onClick={() => navigate(ROUTES.GROUPS)}>
        Przejdz do zarzadzania grupami
      </Button>
    </StyledCard>
  );
};
