import React, { useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Alert, Button, Empty, Table, Typography } from 'antd';
import { LeagueWeekendMatchesToBet } from 'api/matches';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

import CustomInputNumber from 'components/atoms/InputNumber';
import { Spinner } from 'components/atoms/Spinner';
import StyledProgress from 'components/atoms/StyledProgress';
import { TableWrapper } from 'components/atoms/Table';

import { TableTitle } from '../../../components/molecules/TableTitle';

import {
  ButtonWrapper,
  TableFooterWrapper,
  LeagueWrapper,
  FooterButtonWrapper,
  DateWrapper,
  TitleWrapper,
} from './styles';
import { getFormMatchesWithBet, getMatchesWithBet, columns, DataType } from './utils';

const { Text } = Typography;

interface LeagueBetProps {
  isLoading: boolean;
  isSendBetLoading: boolean;
  handleBetSend: (data: FieldValues) => Promise<void>;
  error?: FetchBaseQueryError | SerializedError;
  data?: LeagueWeekendMatchesToBet;
  isSendBetError: boolean;
  title: string;
  subtitle?: string;
}

export const BetTable: React.FC<LeagueBetProps> = ({
  isLoading,
  error,
  data,
  handleBetSend,
  isSendBetLoading,
  isSendBetError,
  title,
  subtitle,
}) => {
  const [bet, setBet] = useState(false);
  const { t } = useTranslation('translation', {
    keyPrefix: 'bet',
  });

  const { control, handleSubmit, register, watch } = useForm();

  const matches = data?.matches || [];
  const weekNumber = data?.week_number || 0;

  const handleBetStart = () => {
    setBet(true);
  };

  if (isLoading) {
    return <Spinner />;
  }

  // @ts-expect-error TODO: Fix
  if (error && error?.data?.details?.code === 100) {
    return (
      <Alert
        message={'Brak meczów do obstawienia'}
        description="Obstawianie meczy bedzie możliwe po ogłoszeniu terminarza spotkań"
        type="warning"
        showIcon
      />
    );
  }

  if (error || isSendBetError) {
    return (
      <Alert
        message={t('errorTitle')}
        description={t('errorDescription')}
        type="error"
        showIcon
      />
    );
  }

  const tableData: DataType[] = matches.map((match, index) => {
    return {
      key: match.id,

      // host
      hostName: match.host.name,
      hostImage: match.host.image,
      hostBetGoals: bet ? (
        <>
          <input
            type="hidden"
            {...register(`matches.${index}.match`)}
            defaultValue={match.id}
          />
          <CustomInputNumber
            name={`matches.${index}.host_goals`}
            control={control}
            min={0}
            max={99}
            defaultValue={match.bet[0]?.host_goals}
          />
        </>
      ) : (
        `${match.bet[0]?.host_goals ?? '-'}`
      ),

      // guest
      guestName: match.guest.name,
      guestImage: match.guest.image,
      guestBetGoals: bet ? (
        <>
          <input
            type="hidden"
            {...register(`matches.${index}.match`)}
            defaultValue={match.id}
          />
          <CustomInputNumber
            name={`matches.${index}.guest_goals`}
            control={control}
            min={0}
            max={99}
            defaultValue={match.bet[0]?.guest_goals}
          />
        </>
      ) : (
        `${match.bet[0]?.guest_goals ?? '-'}`
      ),
    };
  });

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        await handleBetSend(data);
        setBet(false);
      })}
    >
      <LeagueWrapper>
        <TableWrapper data-testid={'betLeague'}>
          <Table
            size="middle"
            locale={{
              emptyText: <Empty description={t('noMatchesToBet')} />,
            }}
            style={{ width: '100%' }}
            title={() => (
              <>
                <TableTitle title={title} subtitle={subtitle} />
                <ButtonWrapper>
                  <TitleWrapper level={4}>Kolejka: {weekNumber}</TitleWrapper>

                  {!bet && (
                    <Button
                      style={{ width: '120px' }}
                      data-testid={'startBet'}
                      type="primary"
                      onClick={handleBetStart}
                    >
                      {t('betMatch')}
                    </Button>
                  )}
                </ButtonWrapper>
                {!bet && (
                  <DateWrapper>
                    <Text>
                      {t('matchesDate')} {formatDate(data?.date_start)} -{' '}
                      {formatDate(data?.date_end)}
                    </Text>
                  </DateWrapper>
                )}
              </>
            )}
            pagination={false}
            footer={() => (
              <>
                <TableFooterWrapper>
                  <StyledProgress
                    percent={
                      bet
                        ? getFormMatchesWithBet(matches, watch)
                        : getMatchesWithBet(matches)
                    }
                  />
                </TableFooterWrapper>

                {bet && (
                  <FooterButtonWrapper>
                    <Button
                      style={{ width: '120px' }}
                      data-testid={'sendBet'}
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isSendBetLoading}
                    >
                      {t('sendBet')}
                    </Button>
                  </FooterButtonWrapper>
                )}
              </>
            )}
            columns={columns}
            dataSource={tableData}
          />
        </TableWrapper>
      </LeagueWrapper>
    </form>
  );
};

export default BetTable;
