import React from 'react';

import { useFetchLeagueWeekendMatchesToBetQuery, useSendBetMutation } from 'api/matches';
import { FieldValues } from 'react-hook-form';

import BetTable from './BetTable';

export const GeneralLeagueBet: React.FC<{ id: string; title: string }> = ({
  id,
  title,
}) => {
  const { data, isLoading, error } = useFetchLeagueWeekendMatchesToBetQuery({ id });

  const weekNumber = data?.week_number || 0;
  const matchWeek: number = data?.match_week || 0;

  const [sendBet, { isLoading: isSendBetLoading, isError: isSendBetError }] =
    useSendBetMutation();

  const handleBetSend = async (data: FieldValues) => {
    await sendBet({
      match_week: matchWeek,
      week_number: weekNumber,
      matches: Object.values(data?.matches),
    }).then();
  };

  return (
    <BetTable
      title={title}
      isLoading={isLoading}
      isSendBetLoading={isSendBetLoading}
      handleBetSend={handleBetSend}
      isSendBetError={isSendBetError}
      error={error}
      data={data}
    />
  );
};
