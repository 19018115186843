import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { Group } from 'api/groups';

import { GroupDetails } from './GroupDetails';

interface EntryProps {
  group: Group;
  onEdit: (initialData: Group) => void;
}

export const Entry = ({ group, onEdit }: EntryProps) => {
  return (
    <GroupDetails
      {...group}
      control={
        <Button
          variant="solid"
          onClick={() => {
            onEdit(group);
          }}
          icon={<EditOutlined />}
        >
          Edytuj
        </Button>
      }
    />
  );
};

interface OwnedGroupsProps {
  ownedGroups?: Group[];
  onEdit: (initialData: Group) => void;
}

export const OwnedGroups = ({ ownedGroups, onEdit }: OwnedGroupsProps) => {
  return (
    <List
      data-testid="ownedGroups"
      pagination={false}
      dataSource={ownedGroups}
      renderItem={(group: Group, index) => (
        <Entry group={group} onEdit={onEdit} data-testid={`ownedGroups-${index}`} />
      )}
    />
  );
};
