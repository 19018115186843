import { ThemeConfig, theme } from 'antd';

export const antdLightTheme: ThemeConfig = {
  token: {
    ...theme.defaultConfig.token,
    colorPrimary: '#13c2c2',
    colorInfo: '#60faf3',
    colorSuccess: '#FF9500',
  },
  components: {
    Menu: {
      itemHeight: 60,
      iconSize: 16,
      collapsedIconSize: 20,
    },
    Layout: {
      headerBg: '#ffffff',
    },
    Typography: {
      titleMarginBottom: '0',
    },
  },
};

export const antdDarkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  ...antdLightTheme,
  token: {
    ...antdLightTheme.token,
    colorBgContainer: '#1f1f1f',
  },
  components: {
    ...antdLightTheme.components,
    Layout: {
      headerBg: '#1e1e1e',
      siderBg: '#1e1e1e',
    },
    Menu: {
      // @ts-expect-error - missing in antd types
      ...antdLightTheme.components.Menu,
      darkItemBg: '#1e1e1e',
    },
  },
};
