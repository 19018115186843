import { QUERY_KEYS } from 'constants/routes';

import React from 'react';

import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LandingLayout } from 'components/templates/LadingLayout';

import { GeneralRankings } from './GeneralRankings';

const Wrapper = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.token.colorBgContainer};
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.xs}) {
    padding: 8px;
  }
`;

export const PublicRankings = () => {
  const [searchParams] = useSearchParams();
  const leagueIdParam = searchParams.get(QUERY_KEYS.LEAGUE_ID);

  if (!leagueIdParam) {
    return null;
  }

  return (
    <LandingLayout>
      <Wrapper>
        <GeneralRankings id={leagueIdParam} isPublic={true} />
      </Wrapper>
    </LandingLayout>
  );
};
