import React from 'react';

import { TableColumnsType } from 'antd';

import { RowWrapper } from 'components/atoms/Table';
import TeamImage from 'components/atoms/TeamImage';

import { InputsWrapper, NameWrapper, SeparatorWrapper } from '../styles';

export interface DataType {
  key: React.Key;
  hostName: string;
  hostImage: string;
  guestName: string;
  guestImage: string;
  guestBetGoals: React.ReactNode;
  hostBetGoals: React.ReactNode;
}

export const columns: TableColumnsType<DataType> = [
  {
    title: 'Gospodarz - Gość',
    render: (record: DataType) => (
      <>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.hostImage} />
            {record.hostName}
          </NameWrapper>
          <span>{record.hostBetGoals}</span>
        </RowWrapper>
        <RowWrapper>
          <NameWrapper>
            <TeamImage margin={'0'} src={record.guestImage} />
            {record.guestName}
          </NameWrapper>
          <span>{record.guestBetGoals}</span>
        </RowWrapper>
      </>
    ),
    responsive: ['xs'],
  },
  {
    title: 'Gospodarz',
    render: (record: DataType) => (
      <>
        {record.hostName} <TeamImage margin={'0 0 0 8px'} src={record.hostImage} />
      </>
    ),
    align: 'right',
    responsive: ['sm'],
  },
  {
    title: 'Twój typ',
    render: (record: DataType) => (
      <InputsWrapper>
        {record.hostBetGoals} <SeparatorWrapper>:</SeparatorWrapper>{' '}
        {record.guestBetGoals}
      </InputsWrapper>
    ),
    align: 'center',
    responsive: ['sm'],
  },
  {
    title: 'Gość',
    render: (record: DataType) => (
      <>
        <TeamImage src={record.guestImage} />
        {record.guestName}
      </>
    ),
    align: 'left',
    responsive: ['sm'],
  },
];
