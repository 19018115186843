import { ROUTES } from 'constants/routes';

import React, { useEffect, useState } from 'react';

import { Layout, Menu, Grid } from 'antd';
import { useScrollTop, usePushPermission } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthInfo } from 'store/authSlice';

import Logo from 'components/atoms/Logo';
import MobileMenu from 'components/atoms/MobileMenu';

import 'theme/sanitize.css';
import 'theme/fonts.css';

import AvatarDropdown from './AvatarDropdown';
import Header from './Header';
import { desktopMenuItemsConfig, mobileOnlyMenuItemsConfig } from './config';
import {
  Wrapper,
  StyledSider,
  LogoWrapper,
  StyledHeader,
  HeaderWrapper,
  ItemsWrapper,
  LeftHeader,
} from './styles';

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const authInfo = useSelector(selectAuthInfo);
  const navigate = useNavigate();

  useScrollTop();
  usePushPermission();

  useEffect(() => {
    if (authInfo.isUserLoggedIn === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [authInfo.isUserLoggedIn, navigate]);

  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState(true);
  const [isVisible, seIsVisible] = useState(screens.md);

  const { t } = useTranslation('translation', {
    keyPrefix: 'menu',
  });
  const { pathname } = useLocation();

  const mainMenuItems = [
    ...desktopMenuItemsConfig,
    ...((!screens.sm && mobileOnlyMenuItemsConfig) || []),
  ];

  const activeElements = [
    Object.values(ROUTES).find((route) => route === pathname) || ROUTES.DASHBOARD,
  ];

  return (
    <Wrapper>
      <Layout>
        <StyledSider
          style={{
            position: 'sticky',
          }}
          $isVisible={isVisible}
          trigger={null}
          collapsible
          collapsed={screens.sm ? collapsed : false}
          collapsedWidth={100}
        >
          <LogoWrapper>
            <Logo white />
          </LogoWrapper>

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={activeElements}
            selectedKeys={activeElements}
            items={[
              ...mainMenuItems.map(({ icon, label, to }) => ({
                label: t(label),
                onClick: () => {
                  if (!screens.md) seIsVisible(false);
                  navigate(to);
                },
                key: to,
                icon,
              })),
            ]}
          />
        </StyledSider>

        <Layout>
          <StyledHeader>
            <HeaderWrapper>
              <LeftHeader>
                <Header
                  handleVisibility={seIsVisible}
                  handleCollapse={setCollapsed}
                  collapsed={collapsed}
                />
              </LeftHeader>
              <ItemsWrapper>
                <AvatarDropdown />
              </ItemsWrapper>
            </HeaderWrapper>
          </StyledHeader>

          <Content
            style={{
              paddingTop: 24,
              paddingLeft: screens.sm ? 24 : 8,
              paddingRight: screens.sm ? 24 : 8,
              paddingBottom: 200,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      {!screens.sm && <MobileMenu items={mainMenuItems.slice(0, 4)} />}
    </Wrapper>
  );
};

export default DashboardLayout;
