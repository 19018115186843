import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Popconfirm, Typography } from 'antd';
import {
  CreateGroupPayload,
  Group,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useEditGroupMutation,
} from 'api/groups';
import { useValidationRules } from 'hooks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputText from 'components/atoms/InputText';
import { LeagueSelect } from 'components/molecules/LeagueSelect';

import UserSearch from './UserSearch';

const maxDescriptionLength = 128;
const { Title } = Typography;

interface GroupFormProps {
  onClose: () => void;
  initialData?: Group;
}

export const GroupForm = ({ initialData, onClose }: GroupFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'group',
  });

  const rules = useValidationRules();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isEdit = Boolean(initialData?.id);

  const [
    createGroup,
    {
      isLoading: isCreateGroupLoading,
      isError: isCreateGroupError,
      isSuccess: isCreateGroupSuccess,
    },
  ] = useCreateGroupMutation();

  const [
    editGroup,
    {
      isLoading: isEditGroupLoading,
      isError: isEditGroupError,
      isSuccess: isEditGroupSuccess,
    },
  ] = useEditGroupMutation();

  const [
    deleteGroup,
    {
      isLoading: isDeleteGroupLoading,
      isError: isDeleteGroupError,
      isSuccess: isDeleteGroupSuccess,
    },
  ] = useDeleteGroupMutation();

  const handleUserFormSubmit = async (data: CreateGroupPayload) => {
    if (isEdit && initialData) {
      editGroup({ id: initialData.id, ...data });
    } else {
      createGroup(data);
    }
  };

  if (isCreateGroupSuccess || isEditGroupSuccess || isDeleteGroupSuccess) {
    onClose();
  }

  if (isCreateGroupError || isEditGroupError || isDeleteGroupError) {
    return (
      <Alert
        message="Wystąpił błąd podczas zapisywania danych, spróbuj później"
        type="error"
      />
    );
  }

  return (
    <form
      onSubmit={handleSubmit(({ name, description, members, league }) =>
        handleUserFormSubmit({ name, description, members, league }),
      )}
    >
      <Flex vertical gap={32}>
        <div>
          <Title level={4}>{isEdit ? 'Edycja grupy' : 'Tworzenie nowej grupy'}</Title>
          <InputText
            size="large"
            name="name"
            defaultValue={initialData?.name}
            label={t('groupName')}
            control={control}
            errors={errors}
            rules={rules.groupName}
          />
          <InputText
            name="description"
            label={t('description')}
            control={control}
            errors={errors}
            defaultValue={initialData?.description}
            rules={rules.groupDescription}
            type="textArea"
            maxLength={maxDescriptionLength}
            showCount
          />
          {!isEdit && (
            <LeagueSelect
              rules={rules.groupLeague}
              name="league"
              errors={errors}
              control={control}
            />
          )}

          <UserSearch
            name="members"
            errors={errors}
            defaultValue={initialData?.members}
            // rules={rules.groupUsers}
            control={control}
            help="Możesz dodać maksymalnie 9 użytkowników."
          />
        </div>

        <Flex gap={16} justify="flex-end">
          <Button
            size="large"
            onClick={() => onClose()}
            type="default"
            disabled={isCreateGroupLoading || isEditGroupLoading || isDeleteGroupLoading}
          >
            Anuluj
          </Button>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            disabled={isCreateGroupLoading || isEditGroupLoading || isDeleteGroupLoading}
          >
            {initialData ? 'Zapisz zmiany' : 'Utwórz'}
          </Button>
        </Flex>

        {initialData && (
          <Flex>
            <Popconfirm
              title="Czy na pewno chcesz usunąć grupę?"
              description="Usunięcie grupy spowoduje usunięcie wszystkich jej członków."
              okText="Tak"
              cancelText="Nie"
              onConfirm={() => deleteGroup({ id: initialData.id })}
            >
              <Button type="default" icon={<DeleteOutlined />}>
                usuń grupę
              </Button>
            </Popconfirm>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
