import React, { useState } from 'react';

import { Alert, Button, Flex, Empty } from 'antd';
import {
  Group,
  useGetUserMemberGroupsQuery,
  useGetUserOwnedGroupsQuery,
} from 'api/groups';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GroupsThemeProvider } from 'theme/GroupsThemeProvider';

import CardTitle from 'components/atoms/CardTitle';
import Grid from 'components/atoms/Grid';
import { Spinner } from 'components/atoms/Spinner';
import { StyledCard } from 'components/atoms/styles';

import { GroupForm } from './GroupForm';
import { Invitations } from './Invitations';
import { MemberGroups } from './MemberGroups';
import { OwnedGroups } from './OwnedGroups';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Groups = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'group',
  });
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [initalFormData, setInitialFormData] = useState<Group | undefined>(undefined);

  const {
    data: memberGroups,
    error: memberGroupsError,
    isLoading: memberGroupsIsLoading,
  } = useGetUserMemberGroupsQuery();

  const {
    data: ownedGroups,
    error: ownedGroupsError,
    isLoading: ownedGroupsIsLoading,
  } = useGetUserOwnedGroupsQuery();

  if (memberGroupsIsLoading || ownedGroupsIsLoading) {
    return <Spinner />;
  }

  if (memberGroupsError || ownedGroupsError) {
    return (
      <Alert
        message={'Wystąpił błąd z pobraniem grup'}
        description={'Nie udało się pobrać '}
        type="error"
        showIcon
      />
    );
  }

  const hasAnyGroup = !!memberGroups?.length || !!ownedGroups?.length;

  return (
    <GroupsThemeProvider>
      <Grid
        aside={
          <StyledCard
            title={
              <Flex justify="space-between" align="center">
                <CardTitle>Zaproszenia</CardTitle>
              </Flex>
            }
          >
            <Invitations />
          </StyledCard>
        }
      >
        <StyledCard
          title={
            <Flex justify="space-between" align="center">
              <CardTitle>Twoje Grupy</CardTitle>
            </Flex>
          }
        >
          {isFormOpen ? (
            <GroupForm
              initialData={initalFormData}
              onClose={() => setIsFormOpen(false)}
            />
          ) : (
            <Flex vertical gap={16}>
              {hasAnyGroup ? (
                <Flex vertical gap={32}>
                  {!!memberGroups?.length && <MemberGroups memberGroups={memberGroups} />}
                  {!!ownedGroups?.length && (
                    <OwnedGroups
                      onEdit={(initialData) => {
                        setInitialFormData(initialData);
                        setIsFormOpen(true);
                      }}
                      ownedGroups={ownedGroups}
                    />
                  )}
                </Flex>
              ) : (
                <Empty description={t('noGroups')} />
              )}

              <Flex vertical gap={32}>
                <Flex justify={'center'}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setInitialFormData(undefined);
                      setIsFormOpen(true);
                    }}
                  >
                    Utwórz nową grupę
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
        </StyledCard>
      </Grid>
    </GroupsThemeProvider>
  );
};

export default Groups;
