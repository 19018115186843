import React from 'react';

import { Table, TableColumnsType } from 'antd';

import { UsernameCell } from '../UserCell';
import { RowWrapper, UserNameWrapper } from '../styles';

export interface DataType {
  key: React.Key;
  rank: number;
  name: string;
  points: number;
  teamName?: string;
  teamImage?: string;
  avatar?: string;
  userId: number;
}

export const columns: TableColumnsType<DataType> = [
  {
    title: 'Pozycja',
    dataIndex: 'rank',
    key: 'rank',
    align: 'right',
    responsive: ['sm'],
    width: 45,
  },
  {
    dataIndex: 'rank',
    key: 'rank',
    align: 'right',
    responsive: ['xs'],
  },
  {
    title: 'Użytkownik',
    responsive: ['xs'],
    render: (record: DataType) => (
      <RowWrapper>
        <UserNameWrapper>
          <UsernameCell
            name={record.name}
            teamName={record.teamName}
            teamImage={record.teamImage}
            avatar={record.avatar}
          />
        </UserNameWrapper>
        {record.points}
      </RowWrapper>
    ),
  },
  {
    title: 'Użytkownik',
    key: 'name',
    align: 'left',
    responsive: ['sm'],
    render: (record: DataType) => (
      <UsernameCell
        name={record.name}
        teamName={record.teamName}
        teamImage={record.teamImage}
        avatar={record.avatar}
      />
    ),
  },
  {
    title: 'Punkty',
    dataIndex: 'points',
    key: 'points',
    align: 'right',
    responsive: ['sm'],
  },
  Table.EXPAND_COLUMN,
];
