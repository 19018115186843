import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import { RankingsAvailableWeeksApiResponse } from 'api/rankings';
import { SearchTeamsResponse } from 'api/teams';

import TeamImage from 'components/atoms/TeamImage';

import { FiltersWrapper, TeamFilterWrapper, LabelWrapper } from './styles';

interface RankingTableProps {
  onTeamSelect: (team: string) => void;
  availableWeeks: RankingsAvailableWeeksApiResponse[] | undefined;
  teams: SearchTeamsResponse | undefined;
  onWeekSelect: (week: number | null) => void;
  onPageChange: (page: number) => void;
}

export const Filters = ({
  onTeamSelect,
  availableWeeks,
  teams,
  onWeekSelect,
  onPageChange,
}: RankingTableProps) => {
  const availableWeeksIdsOptions = [
    {
      value: null,
      label: 'Wszystkie',
    },
    ...(
      availableWeeks?.map((week) => ({
        value: week.id,
        label: week.number,
      })) || []
    ).reverse(),
  ];

  const teamSelectOptions = [
    {
      value: 'none',
      label: 'Wszystkie',
    },
    ...(teams?.results.map((team) => ({
      value: String(team.id),
      label: (
        <>
          <TeamImage size={16} src={team.image} />
          {team.name}
        </>
      ),
    })) || []),
  ];

  return (
    <FiltersWrapper>
      <TeamFilterWrapper>
        <Form.Item
          label={
            <LabelWrapper>
              {'Klub'}
              <Tooltip title="Filtruj ranking po klubach należących do danej ligi. Przynależność do klubu możesz ustawić w swoim profilu.">
                <InfoCircleOutlined />
              </Tooltip>
            </LabelWrapper>
          }
          labelCol={{ span: 24 }}
        >
          <Select
            defaultValue={'none'}
            optionFilterProp="children"
            allowClear
            onChange={(team) => {
              onTeamSelect(team);
              onPageChange(1);
            }}
            options={teamSelectOptions}
          />
        </Form.Item>
      </TeamFilterWrapper>
      <Form.Item style={{ width: 100 }} label={'Kolejka'} labelCol={{ span: 24 }}>
        <Select
          defaultValue={null}
          optionFilterProp="children"
          allowClear
          onChange={(week) => {
            onWeekSelect(week);
            onPageChange(1);
          }}
          options={availableWeeksIdsOptions}
        />
      </Form.Item>
    </FiltersWrapper>
  );
};
