import React, { useState } from 'react';

import { Form, Select } from 'antd';
import { useSearchLeaguesQuery } from 'api/leagues';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  RegisterOptions,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatError } from 'utils';

interface LeagueSelectProps {
  control: Control;
  onChange?: () => void;
  name: string;
  errors?: FieldErrors;
  help?: string;
  rules: RegisterOptions;
}

const LeagueSelect: React.FC<LeagueSelectProps> = ({
  control,
  onChange,
  name,
  rules,
  errors,
  help,
}) => {
  const [searchPhrase, setSearch] = useState('');

  const error = errors?.[name] as FieldError | undefined;

  const displayError = formatError(error);

  const { t } = useTranslation('translation', {
    keyPrefix: 'teamSearch',
  });

  const { data: searchResults, isLoading } = useSearchLeaguesQuery(
    {
      page: 1,
      pageSize: 10,
      search: searchPhrase,
    },
    { skip: searchPhrase === '' },
  );

  const apiOptions =
    searchResults?.results.map((league) => ({
      value: String(league.id),
      label: league.title,
    })) || [];

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Form.Item
          labelCol={{ span: 24 }}
          label={'Liga'}
          validateStatus={error && 'error'}
          help={error ? displayError : help}
        >
          <Select
            {...field}
            size="large"
            loading={isLoading}
            style={{ width: '100%', textAlign: 'left' }}
            showSearch
            placeholder={t('placeholder')}
            allowClear={true}
            optionFilterProp="children"
            options={apiOptions}
            filterOption={filterOption}
            onSearch={(source) => {
              setSearch(source);
            }}
            onChange={(value) => {
              field.onChange(value);
              onChange?.();
            }}
          />
        </Form.Item>
      )}
    />
  );
};

export default LeagueSelect;
