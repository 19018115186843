import { QUERY_KEYS } from 'constants/routes';

import React, { useLayoutEffect, useState } from 'react';

import Icon from '@ant-design/icons';
import { Button } from 'antd';
import { ReactComponent as PeopleSvg } from 'assets/icons/main-menu/people.svg';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export enum viewMode {
  groups = 'groups',
  general = 'general',
}

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto 32px;
`;

interface ModeSwitchProps {
  componentConfig: Record<viewMode, React.ReactNode>;
}

const ModeSwitch: React.FC<ModeSwitchProps> = ({ componentConfig }) => {
  const [mode, setMode] = useState<viewMode>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modeId = searchParams.get(QUERY_KEYS.MODE_ID);

  useLayoutEffect(() => {
    setMode((modeId ? modeId : viewMode.general) as viewMode);
  }, [modeId]);

  return (
    <>
      <ButtonsWrapper>
        <StyledButton
          variant={mode === viewMode.general ? 'solid' : 'outlined'}
          color="primary"
          size={'large'}
          onClick={() => {
            searchParams.set(QUERY_KEYS.MODE_ID, viewMode.general);
            setSearchParams(searchParams, { replace: true });
            setMode(viewMode.general);
          }}
        >
          Generalne
        </StyledButton>
        <StyledButton
          variant={mode === viewMode.general ? 'outlined' : 'solid'}
          size={'large'}
          color="cyan"
          onClick={() => {
            searchParams.set(QUERY_KEYS.MODE_ID, viewMode.groups);
            setSearchParams(searchParams, { replace: true });
            setMode(viewMode.groups);
          }}
        >
          Grupy
          <Icon component={PeopleSvg} />
        </StyledButton>
      </ButtonsWrapper>
      {mode && componentConfig[mode]}
    </>
  );
};

export default ModeSwitch;
