import React from 'react';

import { Button, Flex, List, Typography } from 'antd';
import {
  Invitation,
  useGetGroupInvitationsQuery,
  useUpdateInvitationMutation,
} from 'api/groups';

const { Title } = Typography;

export const Invitations = () => {
  const { data: invitations } = useGetGroupInvitationsQuery();
  const [updateInvitation, { isLoading }] = useUpdateInvitationMutation();

  const pendingInvitations = (invitations?.results || []).filter(
    ({ status }) => status === 'pending',
  );

  if (!pendingInvitations.length) {
    return <>Nie masz oczekujących zaproszeń do akceptacji</>;
  }

  return (
    <>
      <Title level={4}>Otrzymane zaproszenia</Title>
      <List
        data-testid="invitations"
        pagination={false}
        dataSource={pendingInvitations}
        renderItem={(invitation: Invitation) => (
          <List.Item>
            <List.Item.Meta
              title={<>{invitation.group_name}</>}
              description={
                <div>
                  {invitation.description}
                  <br />
                  od: {invitation.sender}
                  <Flex justify="flex-end">
                    <Button
                      disabled={isLoading}
                      onClick={() =>
                        updateInvitation({ id: invitation.id, status: 'accepted' })
                      }
                      type={'text'}
                    >
                      Akceptuj
                    </Button>
                    <Button
                      onClick={() =>
                        updateInvitation({ id: invitation.id, status: 'rejected' })
                      }
                      danger
                      type={'text'}
                    >
                      Odrzuć
                    </Button>
                  </Flex>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
