import { QUERY_KEYS, ROUTES } from 'constants/routes';

import React, { useEffect, useState } from 'react';

import { Button, Select, Typography } from 'antd';
import { Weekend, useGetGroupLeagueMatchesQuery } from 'api/matches';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { viewMode } from 'components/atoms/ModeSwtich';
import { ContentComponentProps } from 'components/molecules/GroupDataNavigation/GroupDataNavigation';
import { ResultsTable } from 'components/molecules/ResultsTable';

import { LeagueWrapper, SelectWrapper } from './styles';

const { Title } = Typography;

const initialWeekend: Weekend = {
  date_end: '',
  date_start: '',
  matches: [],
  number: 0,
  is_current: false,
};

export const GroupResults: React.FC<ContentComponentProps> = ({
  groupId,
  title,
  subtitle,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'results',
  });

  const navigate = useNavigate();
  const [currentWeek, setCurrentWeek] = useState<Weekend>(initialWeekend);

  const { data, error, isLoading } = useGetGroupLeagueMatchesQuery({
    groupId,
  });
  const matches = data?.weekends || [];

  // default week is the last one with results or first one

  const defaultWeek =
    matches
      .slice()
      .reverse()
      .find((weekend) =>
        weekend.matches.some(
          (match) => match.host_goals !== null && match.guest_goals !== null,
        ),
      ) ||
    matches[0] ||
    initialWeekend;

  useEffect(() => {
    setCurrentWeek(defaultWeek);
  }, [defaultWeek]);

  const selectOptions = matches?.map((week) => {
    return {
      value: week.number,
      label: JSON.stringify(week.number),
    };
  });

  const handleSelect = (value: number) => {
    const selectedWeek = matches.find((weekend) => weekend.number == value);
    setCurrentWeek(selectedWeek || defaultWeek);
  };

  return (
    <LeagueWrapper>
      <ResultsTable
        matches={currentWeek?.matches}
        date_start={currentWeek.date_start}
        date_end={currentWeek.date_end}
        isLoading={isLoading}
        hasError={Boolean(error)}
        title={title}
        subtitle={subtitle}
        tableHeader={
          <>
            <SelectWrapper>
              <Title level={4}>{t('round')}</Title>
              <Select
                style={{ width: 80, margin: '0 10px' }}
                placeholder="Wybierz kolejkę"
                optionFilterProp="children"
                defaultValue={defaultWeek.number}
                onChange={handleSelect}
                options={selectOptions}
              />
            </SelectWrapper>
            <Button
              style={{ padding: '0 8px' }}
              type="primary"
              onClick={() =>
                navigate({
                  pathname: ROUTES.BET,
                  search: `?${QUERY_KEYS.MODE_ID}=${viewMode.groups}&&${QUERY_KEYS.GROUP_ID}=${groupId}`,
                })
              }
            >
              Idź do typowania
            </Button>
          </>
        }
      />
    </LeagueWrapper>
  );
};

export default GroupResults;
