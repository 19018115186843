import React from 'react';

import { theme } from 'antd';
import { ConfigProvider } from 'antd';
import { useDarkmode } from 'hooks';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './GlobalStyles';
import { styledComponentsTheme, antdDarkTheme, antdLightTheme } from './generalTheme';

interface GeneralThemeProviderProps {
  children: React.ReactNode;
}

export const GeneralThemeProvider = ({ children }: GeneralThemeProviderProps) => {
  const { token: antDefaultTokens } = theme.useToken();
  const isDarkMode = useDarkmode();

  const customTokens = isDarkMode ? antdDarkTheme : antdLightTheme;

  return (
    <>
      <GlobalStyle />
      <ThemeProvider
        theme={{
          ...styledComponentsTheme,
          token: { ...antDefaultTokens, ...customTokens.token },
        }}
      >
        <ConfigProvider theme={customTokens}>{children}</ConfigProvider>
      </ThemeProvider>
    </>
  );
};
