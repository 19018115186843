import React from 'react';

import CardTitle from 'components/atoms/CardTitle';
import ModeSwitch, { viewMode } from 'components/atoms/ModeSwtich';
import DataNavigation from 'components/molecules/DataNavigation/DataNavigation';
import GroupDataNavigation from 'components/molecules/GroupDataNavigation/GroupDataNavigation';
import { ContentCard } from 'components/templates/DashboardLayout/styles';

import GroupResults from './GroupResults';
import LeagueResults from './LeagueResults';

const Results: React.FC = () => {
  const config = {
    [viewMode.general]: (
      <ContentCard title={<CardTitle>Wyniki Generalne</CardTitle>}>
        <DataNavigation ContentComponent={LeagueResults} />
      </ContentCard>
    ),
    [viewMode.groups]: (
      <ContentCard title={<CardTitle>Wyniki grupowe</CardTitle>}>
        <GroupDataNavigation ContentComponent={GroupResults} />
      </ContentCard>
    ),
  };

  return <ModeSwitch componentConfig={config} />;
};

export default Results;
