import React from 'react';

import { EyeFilled } from '@ant-design/icons';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Alert, Empty, Table, Button } from 'antd';
import {
  apiPageSize,
  RankingsApiResponse,
  RankingsAvailableWeeksApiResponse,
} from 'api/rankings';
import { SearchTeamsResponse } from 'api/teams';
import { useGetMeQuery } from 'api/users';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/atoms/Spinner';
import { TableTitle } from 'components/molecules/TableTitle';

import { Filters } from './Filters';
import { RankingUserResults } from './RankingUserResults';
import { TableWrapper } from './styles';
import { getRowClassName, columns, DataType } from './utils';
import { mapRankingTableData } from './utils/utils';

interface RankingTableProps {
  availableWeeks: RankingsAvailableWeeksApiResponse[] | undefined;
  currentWeek: number | null;
  error: FetchBaseQueryError | SerializedError | undefined;
  footer?: React.ReactNode;
  isLoading: boolean;
  isPublic?: boolean;
  leagueId: string;
  onPageChange: (page: number) => void;
  onTeamSelect: (team: string) => void;
  onWeekSelect: (week: number | null) => void;
  page: number;
  ranking: RankingsApiResponse | undefined;
  subtitle?: string;
  teams: SearchTeamsResponse | undefined;
  title: string;
}

export const RankingTable = ({
  availableWeeks,
  currentWeek,
  error,
  footer,
  isLoading,
  isPublic,
  leagueId,
  onPageChange,
  onTeamSelect,
  onWeekSelect,
  page,
  ranking,
  subtitle,
  teams,
  title,
}: RankingTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'rankings',
  });

  const { data: meData } = useGetMeQuery();

  const rankingResults = ranking?.results || [];

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert
        message={t('errorTitle')}
        description={t('errorDescription')}
        type="error"
        showIcon
      />
    );
  }

  const rankingTableData: DataType[] = rankingResults.map(mapRankingTableData);

  const latestWeekNumber =
    availableWeeks && availableWeeks[availableWeeks.length - 1]?.id;
  const weekNumber = currentWeek || latestWeekNumber;
  const showTypes = !isPublic && weekNumber;

  return (
    <TableWrapper>
      <Table
        size="middle"
        locale={{
          emptyText: <Empty description={'Brak danych do wyświetlenia'} />,
        }}
        footer={() => footer}
        title={() => (
          <>
            <TableTitle title={title} subtitle={subtitle} />
            <Filters
              onPageChange={onPageChange}
              teams={teams}
              onTeamSelect={onTeamSelect}
              onWeekSelect={onWeekSelect}
              availableWeeks={availableWeeks}
            />
          </>
        )}
        pagination={{
          showSizeChanger: false,
          pageSize: apiPageSize,
          current: page,
          onChange: (newPage) => onPageChange(newPage),
          total: ranking?.count || 0,
        }}
        rowClassName={getRowClassName(meData?.username || '')}
        expandable={
          showTypes
            ? {
                columnWidth: 0,
                expandedRowRender: (record) => (
                  <RankingUserResults
                    leagueId={leagueId}
                    selectedUserId={record.userId}
                    week={weekNumber}
                  />
                ),
                expandIcon: ({ onExpand, record }) => (
                  <Button
                    icon={<EyeFilled size={12} />}
                    onClick={(e) => onExpand(record, e)}
                    size="small"
                  >
                    Typy
                  </Button>
                ),
              }
            : {}
        }
        dataSource={rankingTableData}
        columns={columns}
      />
    </TableWrapper>
  );
};
