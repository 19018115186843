import { QUERY_KEYS, ROUTES } from 'constants/routes';

import React from 'react';

import { Button, Card, Typography, Flex, List } from 'antd';
import { Group } from 'api/groups';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Avatar from 'components/atoms/Avatar';
import { viewMode } from 'components/atoms/ModeSwtich';

interface GroupDetailsProps extends Group {
  control?: React.ReactNode;
}

const { Title, Paragraph } = Typography;

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.token.cyan};};
`;

const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  padding-top: 16px;
  align-items: center;
`;

export const GroupDetails = ({
  name,
  description,
  id: groupId,
  control,
  members,
  league,
}: GroupDetailsProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Card>
        <Inner>
          <div>
            <Flex gap={'8px'} justify={'space-between'}>
              <Title level={3}>{name}</Title>

              <Button
                type="primary"
                onClick={() =>
                  navigate({
                    pathname: ROUTES.BET,
                    search: `?${QUERY_KEYS.MODE_ID}=${viewMode.groups}&&${QUERY_KEYS.GROUP_ID}=${groupId}`,
                  })
                }
              >
                Typuj
              </Button>
            </Flex>
            <Paragraph type="secondary">{description}</Paragraph>
            <Paragraph>Rozgrywki: {league.title}</Paragraph>
          </div>

          <List
            pagination={false}
            dataSource={members}
            renderItem={(member) => (
              <UserWrapper>
                <Avatar
                  username={member?.username || ''}
                  size={40}
                  src={member?.avatar}
                />
                <p>{member?.username}</p>
              </UserWrapper>
            )}
          />

          <Footer>
            <Button
              variant="outlined"
              onClick={() =>
                navigate({
                  pathname: ROUTES.RANKING,
                  search: `?${QUERY_KEYS.MODE_ID}=${viewMode.groups}&&${QUERY_KEYS.GROUP_ID}=${groupId}`,
                })
              }
            >
              Rankingi
            </Button>

            <Button
              variant="outlined"
              onClick={() =>
                navigate({
                  pathname: ROUTES.RESULTS,
                  search: `?${QUERY_KEYS.MODE_ID}=${viewMode.groups}&&${QUERY_KEYS.GROUP_ID}=${groupId}`,
                })
              }
            >
              Wyniki
            </Button>
            {control}
          </Footer>
        </Inner>
      </Card>
    </Wrapper>
  );
};
