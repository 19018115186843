import React from 'react';

import CardTitle from 'components/atoms/CardTitle';
import ModeSwitch, { viewMode } from 'components/atoms/ModeSwtich';
import DataNavigation from 'components/molecules/DataNavigation/DataNavigation';
import GroupDataNavigation from 'components/molecules/GroupDataNavigation/GroupDataNavigation';
import { ContentCard } from 'components/templates/DashboardLayout/styles';

import { GeneralRankings } from './GeneralRankings';
import { GroupRankings } from './GroupRankings';

const Rankings = () => {
  const config = {
    [viewMode.general]: (
      <ContentCard title={<CardTitle>Rankingi Generalne</CardTitle>}>
        <DataNavigation ContentComponent={GeneralRankings} />
      </ContentCard>
    ),
    [viewMode.groups]: (
      <ContentCard title={<CardTitle>Rankingi Grupowe</CardTitle>}>
        <GroupDataNavigation ContentComponent={GroupRankings} />
      </ContentCard>
    ),
  };

  return <ModeSwitch componentConfig={config} />;
};

export default Rankings;
