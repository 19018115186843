import React, { useState } from 'react';

import { Select, Form } from 'antd';
import { Group, GroupUser, useSearchUsersQuery } from 'api/groups';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  RegisterOptions,
} from 'react-hook-form';
import { formatError } from 'utils';

interface TeamSearchProps {
  control: Control;
  selectedUsers?: GroupUser[];
  onChange?: () => void;
  errors?: FieldErrors;
  help?: string;
  name: string;
  rules?: RegisterOptions;
  defaultValue?: Group['members'];
}

const UserSearch: React.FC<TeamSearchProps> = ({
  control,
  onChange,
  name,
  rules,
  errors,
  help,
  defaultValue,
}) => {
  const [searchPhrase, setSearch] = useState('');

  const error = errors?.[name] as FieldError | undefined;
  const displayError = formatError(error);

  const { data: searchResults, isLoading } = useSearchUsersQuery(
    {
      page: 1,
      pageSize: 10,
      search: searchPhrase,
    },
    { skip: searchPhrase === '' },
  );

  const defaultValues = defaultValue?.map((member) => String(member.id));

  const defaultOptions = defaultValue?.map((user) => ({
    value: String(user.id),
    label: user.username,
  }));

  const options =
    searchResults?.results.map((user) => ({
      value: String(user.id),
      label: user.username,
    })) || defaultOptions;

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Form.Item
            validateStatus={error && 'error'}
            help={error ? displayError : help}
            labelCol={{ span: 24 }}
            label="Użytkownicy"
          >
            <Select
              {...field}
              size={'large'}
              mode="multiple"
              maxCount={9}
              loading={isLoading}
              style={{ width: '100%', textAlign: 'left' }}
              showSearch
              defaultValue={defaultValues}
              placeholder={'Szukaj użytkownika'}
              allowClear={true}
              optionFilterProp="children"
              options={options}
              filterOption={filterOption}
              onSearch={(source) => {
                setSearch(source);
              }}
              onChange={(value) => {
                field.onChange(value);
                onChange?.();
              }}
            />
          </Form.Item>
        )}
      />
    </>
  );
};

export default UserSearch;
