import React, { useState } from 'react';

import {
  useGetGroupTeamsQuery,
  useGetGroupAvailableWeeksQuery,
  useGetUserGroupRankingQuery,
} from 'api/rankings';

import { ContentComponentProps } from 'components/molecules/GroupDataNavigation/GroupDataNavigation';

import { RankingTable } from './RankingTable';

export const GroupRankings = ({
  leagueId,
  groupId,
  title,
  subtitle,
}: ContentComponentProps) => {
  const [page, setPage] = useState(1);
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [week, setWeek] = useState<number | null>(null);

  const { data, isLoading, error } = useGetUserGroupRankingQuery({
    groupId: groupId,
    page,
    teamId: selectedTeamId === 'none' ? '' : selectedTeamId,
    week,
  });

  const { data: availableWeeks } = useGetGroupAvailableWeeksQuery(groupId);
  const { data: teams } = useGetGroupTeamsQuery(groupId);

  return (
    <RankingTable
      title={title}
      subtitle={subtitle}
      ranking={data}
      leagueId={leagueId}
      onTeamSelect={setSelectedTeamId}
      availableWeeks={availableWeeks}
      isLoading={isLoading}
      error={error}
      teams={teams}
      onPageChange={setPage}
      page={page}
      onWeekSelect={setWeek}
      currentWeek={week}
    />
  );
};
