import { ROUTES } from 'constants/routes';

import React from 'react';

import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Logo from 'components/atoms/Logo';
import Social from 'components/atoms/Social';

const { Header, Content, Footer } = Layout;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  background: ${({ theme }) => theme.token.colorBgContainer};
  border-bottom: 1px solid ${({ theme }) => theme.token.colorPrimary};
`;

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto;
  padding: 8px 24px;
  height: auto;
  width: 100%;
  background: transparent;
`;

const StyledFooter = styled(Footer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  position: sticky;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.token.colorPrimary};
  background: ${({ theme }) => theme.token.colorBgContainer};
`;

const StyledButton = styled(Button)`
  margin: 0 10px;
`;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.token.colorBgContainer};
`;

const FooterWrapper = styled.div`
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpointsWidth.sm}) {
    flex-direction: column;
    gap: 8px;
  }
`;

interface LadingLayoutProps {
  children: React.ReactNode;
}

export const LandingLayout = ({ children }: LadingLayoutProps) => {
  const navigate = useNavigate();

  return (
    <StyledLayout>
      <StickyHeader>
        <StyledHeader>
          <Logo />
          <div>
            <StyledButton onClick={() => navigate(ROUTES.LOGIN)} type="primary">
              Zaloguj
            </StyledButton>
            <StyledButton onClick={() => navigate(ROUTES.REGISTER)}>
              Zarejestruj
            </StyledButton>
          </div>
        </StyledHeader>
      </StickyHeader>
      <Content>{children}</Content>

      <StyledFooter>
        <FooterWrapper>
          <Social /> <span> TypTyp ©{new Date().getFullYear()} </span>
        </FooterWrapper>
      </StyledFooter>
    </StyledLayout>
  );
};
