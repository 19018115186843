import React from 'react';

import { theme } from 'antd';
import { ConfigProvider } from 'antd';
import { useDarkmode } from 'hooks';
import { ThemeProvider } from 'styled-components';

import { styledComponentsTheme } from './generalTheme';
import { antdLightTheme, antdDarkTheme } from './groupsTheme';

interface GroupsThemeProviderProps {
  children: React.ReactNode;
}

export const GroupsThemeProvider = ({ children }: GroupsThemeProviderProps) => {
  const { token: antDefaultTokens } = theme.useToken();
  const isDarkMode = useDarkmode();

  const customTokens = isDarkMode ? antdDarkTheme : antdLightTheme;

  return (
    <>
      <ThemeProvider
        theme={{
          ...styledComponentsTheme,
          token: { ...antDefaultTokens, ...customTokens.token },
        }}
      >
        <ConfigProvider theme={customTokens}>{children}</ConfigProvider>
      </ThemeProvider>
    </>
  );
};
