import { createSlice } from '@reduxjs/toolkit';

import { groupsApi } from '../api/groups';
import { Invitation } from '../api/groups';

import { RootState } from './index';

interface InvitationsState {
  invitations: Invitation[] | null;
}

const initialState: InvitationsState = {
  invitations: null,
};

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      groupsApi.endpoints.getGroupInvitations.matchFulfilled,
      (state, { payload }) => {
        state.invitations = payload?.results as unknown as Invitation[];
      },
    );
  },
});

export default invitationsSlice.reducer;

export const selectInvitations = ({ invitations }: RootState) => invitations.invitations;
