import React, { useState } from 'react';

import {
  useGetAvailableWeeksQuery,
  useGetLeagueTeamsQuery,
  useGetUserRankingQuery,
} from 'api/rankings';

import { RankingTable } from './RankingTable';
import { SocialSharing } from './SocialSharing';

export const GeneralRankings = ({ id, isPublic }: { id: string; isPublic?: boolean }) => {
  const [page, setPage] = useState(1);
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [week, setWeek] = useState<number | null>(null);

  const { data, isLoading, error } = useGetUserRankingQuery({
    leagueId: id,
    page,
    teamId: selectedTeamId === 'none' ? '' : selectedTeamId,
    week,
  });

  const { data: availableWeeks } = useGetAvailableWeeksQuery(id);
  const { data: teams } = useGetLeagueTeamsQuery(id);

  console.log(data);

  return (
    <RankingTable
      title={data?.league_name || ''}
      ranking={data}
      leagueId={id}
      onTeamSelect={setSelectedTeamId}
      availableWeeks={availableWeeks}
      isLoading={isLoading}
      error={error}
      isPublic={isPublic}
      teams={teams}
      onPageChange={setPage}
      page={page}
      onWeekSelect={setWeek}
      currentWeek={week}
      footer={<SocialSharing id={id} />}
    />
  );
};
