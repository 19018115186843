import styled from 'styled-components';

export const LeagueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: center;
`;

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const Bet = styled.span`
  color: ${({ theme }) => theme.token.colorPrimary};
  font-weight: 700;
`;

export const BetWrapper = styled.div`
  color: ${({ theme }) => theme.token.colorPrimary};
  font-weight: bold;
  border-left: 1px solid lightgray;
  padding-left: 6px;
  margin-left: 6px;
  min-width: 28px;
`;

export const ResultWrapper = styled.div`
  display: flex;
  padding-left: 8px;
  @media (max-width: ${({ theme }) => theme.breakpointsWidth.sm}) {
    margin-right: -12px;
  }
`;

export const ScoreWrapper = styled.span<{ $highlighted?: boolean }>`
  font-weight: ${({ $highlighted }) => ($highlighted ? '700' : 'normal')};
  color: ${({ theme, $highlighted }) =>
    $highlighted ? theme.token.colorSuccess : 'inherit'};
`;

export const TeamNameWrapper = styled.span<{ $bold?: boolean }>`
  font-weight: ${({ $bold }) => ($bold ? '600' : 'normal')};
`;

export const Score = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-weight: 600;
  border-top: ${({ theme }) => `1px solid ${theme.token.colorBorder}`};
`;

export const ScoreSum = styled.div`
  font-weight: 600;
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;
