import { useEffect, useState } from 'react';

export const useDarkmode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
  );

  useEffect(() => {
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = ({ matches }: { matches: boolean }) => setIsDarkMode(matches);
    matcher.addEventListener('change', onChange);

    return () => {
      matcher.removeEventListener('change', onChange);
    };
  }, []);

  return isDarkMode;
};
