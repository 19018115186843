import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authApi } from 'api/auth';
import { groupsApi } from 'api/groups';
import { leaguesApi } from 'api/leagues';
import { matchesApi } from 'api/matches';
import { rankingsApi } from 'api/rankings';
import { teamsApi } from 'api/teams';
import { usersApi } from 'api/users';
import { useDispatch } from 'react-redux';

import appConfigReducer from './appConfigSlice';
import authReducer from './authSlice';
import invitationsReducer from './invitationsSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  invitations: invitationsReducer,
  appConfig: appConfigReducer,
  [matchesApi.reducerPath]: matchesApi.reducer,
  [rankingsApi.reducerPath]: rankingsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [leaguesApi.reducerPath]: leaguesApi.reducer,
  [teamsApi.reducerPath]: teamsApi.reducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: (state, action) => {
      if (action.type === 'auth/resetMe') {
        state = undefined;
      }

      return rootReducer(state, action);
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(matchesApi.middleware)
        .concat(rankingsApi.middleware)
        .concat(authApi.middleware)
        .concat(usersApi.middleware)
        .concat(leaguesApi.middleware)
        .concat(teamsApi.middleware)
        .concat(groupsApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export { authReducer, appConfigReducer, invitationsReducer };

export default setupStore;
