import React from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useWindowScroll } from '@uidotdev/usehooks';
import { Button, Grid, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import 'theme/sanitize.css';
import 'theme/fonts.css';

const { Title } = Typography;

import { desktopMenuItemsConfig, mobileOnlyMenuItemsConfig } from './config';

const { useBreakpoint } = Grid;

interface DashboardLayoutProps {
  handleVisibility: (isVisible: boolean) => void;
  handleCollapse: (isVisible: boolean) => void;
  collapsed: boolean;
}

const Header: React.FC<DashboardLayoutProps> = ({
  handleVisibility,
  handleCollapse,
  collapsed,
}) => {
  const screens = useBreakpoint();

  const [{ y }] = useWindowScroll();

  const { t } = useTranslation('translation', {
    keyPrefix: 'menu',
  });
  const { pathname } = useLocation();

  const mainMenuItems = [
    ...desktopMenuItemsConfig,
    ...((!screens.sm && mobileOnlyMenuItemsConfig) || []),
  ];

  return (
    <>
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={
          screens.sm ? () => handleCollapse(!collapsed) : () => handleVisibility(true)
        }
        style={{
          width: 64,
          height: 64,
        }}
      />
      {mainMenuItems.map(({ to, label }) => {
        if (to === pathname && y && y > 100) {
          return (
            <Title level={3} key={to}>
              {t(label)}
            </Title>
          );
        }

        return null;
      })}
    </>
  );
};

export default Header;
