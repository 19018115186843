import React from 'react';

import { createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import AuthLayout from '../components/templates/AuthLayout';
import { DashboardLayout } from '../components/templates/DashboardLayout';
import { ROUTES } from '../constants/routes';
import LogoutForm from '../containers/LogoutForm';
import { Bet } from '../containers/subpages/Bet';
import { Dashboard } from '../containers/subpages/Dashboard';
import { Groups } from '../containers/subpages/Groups';
import { Rankings } from '../containers/subpages/Rankings';
import { PublicRankings } from '../containers/subpages/Rankings/PublicRankings';
import { Results } from '../containers/subpages/Results';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/*Public routes*/}
      <Route
        element={
          <AuthLayout>
            <Outlet />
          </AuthLayout>
        }
      >
        <Route path={ROUTES.LOGIN} lazy={() => import('../containers/LoginForm')} />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          lazy={() => import('../containers/ForgotPassword')}
        />
        <Route path={ROUTES.LOGOUT} element={<LogoutForm />} />
        <Route path={ROUTES.REGISTER} lazy={() => import('../containers/RegisterForm')} />

        <Route
          path={ROUTES.REGISTER_CONFIRMATION}
          lazy={() => import('../containers/RegisterConfirmation')}
        />
        <Route
          path={ROUTES.SET_NEW_PASSWORD}
          lazy={() => import('../containers/SetNewPassword')}
        />
      </Route>

      <Route
        path={ROUTES.HOME}
        lazy={() => import('../containers/subpages/LandingPage')}
      />

      <Route path={ROUTES.PUBLIC_RANKINGS} element={<PublicRankings />} />

      <Route path={ROUTES.TERMS} lazy={() => import('../containers/subpages/Terms')} />
      <Route
        path={ROUTES.SOCIAL_LANDING}
        lazy={() => import('../containers/subpages/LandingPage')}
      />
      <Route path={'/*'} lazy={() => import('../containers/subpages/NotFound')} />

      {/*Login required*/}
      <Route
        element={
          <DashboardLayout>
            <Outlet />
          </DashboardLayout>
        }
      >
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.GROUPS} element={<Groups />} />
        <Route path={ROUTES.RESULTS} element={<Results />} />
        <Route path={ROUTES.BET} element={<Bet />} />
        <Route path={ROUTES.RANKING} element={<Rankings />} />
        <Route path={ROUTES.FAQ} lazy={() => import('../containers/subpages/Faq')} />
        <Route
          path={ROUTES.PROFILE}
          lazy={() => import('../containers/subpages/Profile')}
        />
      </Route>
    </>,
  ),
);
