import { ThemeConfig, theme } from 'antd';

export const antdLightTheme: ThemeConfig = {
  token: {
    ...theme.defaultConfig.token,
    colorPrimary: '#52c41a',
    colorInfo: '#52c41a',
    colorSuccess: '#FF9500',
  },
  components: {
    Menu: {
      itemHeight: 60,
      iconSize: 16,
      collapsedIconSize: 20,
    },
    Layout: {
      headerBg: '#ffffff',
    },
    Typography: {
      titleMarginBottom: '0',
    },
  },
};

export const antdDarkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  ...antdLightTheme,
  token: {
    ...antdLightTheme.token,
    colorBgContainer: '#1f1f1f',
  },
  components: {
    ...antdLightTheme.components,
    Layout: {
      headerBg: '#1e1e1e',
      siderBg: '#1e1e1e',
    },
    Menu: {
      // @ts-expect-error - missing in antd types
      ...antdLightTheme.components.Menu,
      darkItemBg: '#1e1e1e',
    },
  },
};

// used in styled components
export const styledComponentsTheme = {
  breakpointsWidth: {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  dimensions: {
    maxWidth: '1200px',
    cardsGap: '24px',
  },
};
