import React from 'react';

// import { DeleteOutlined } from '@ant-design/icons';
// import { Button, Popconfirm } from 'antd';
import { List } from 'antd';
import { Group } from 'api/groups';

import { GroupDetails } from './GroupDetails';

interface EntryProps {
  group: Group;
}

const Entry = ({ group }: EntryProps) => {
  // const handleLeaveGroup = () => {
  //   console.log('leave group');
  // };

  return (
    <GroupDetails
      {...group}
      control={
        <div>
          {/* uncomment kiedy wychodzdnie z grupy gotowe na be*/}
          {/*<Popconfirm*/}
          {/*  title="Przestań Uczestniczyć w grupie"*/}
          {/*  description="Czy na pewno chcesz opuścić grupę?"*/}
          {/*  okText="Tak"*/}
          {/*  cancelText="Nie"*/}
          {/*  onConfirm={() => handleLeaveGroup()}*/}
          {/*>*/}
          {/*  <Button type="default" icon={<DeleteOutlined />} />*/}
          {/*</Popconfirm>*/}
        </div>
      }
    />
  );
};

interface MemberGroupsProps {
  memberGroups?: Group[];
}

export const MemberGroups = ({ memberGroups }: MemberGroupsProps) => {
  return (
    <List
      data-testid="memberGroups"
      pagination={false}
      dataSource={memberGroups}
      renderItem={(group: Group) => <Entry group={group} />}
    />
  );
};
