import React, { useRef } from 'react';

import { Grid as AntGrid } from 'antd';
import styled from 'styled-components';

import Grid from 'components/atoms/Grid';
import { LeagueSearch } from 'components/molecules/LeagueSearch';
import SocialCard from 'components/molecules/SocialCard/SocialCard';
import { UserDataDisplay } from 'components/molecules/UserDataDisplay';
import { UserLeagues } from 'components/molecules/UserLeagues';

import { Groups } from './Groups';
import IntroTour from './IntroTour';
import { UserStats } from './UserStats';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.cardsGap};
`;

const { useBreakpoint } = AntGrid;

const Dashboard = () => {
  const screens = useBreakpoint();

  const searchRef = useRef(null);
  const userLeaguesRef = useRef(null);

  return (
    <Grid
      aside={
        <Wrapper>
          <SocialCard />
          <LeagueSearch tourRef={searchRef} />
        </Wrapper>
      }
    >
      <Wrapper>
        <UserDataDisplay />
        <Groups />
        <UserStats />
        <span ref={userLeaguesRef}>
          <UserLeagues />
        </span>
      </Wrapper>

      {!screens.xs && <IntroTour />}
    </Grid>
  );
};

export default Dashboard;
