import { Results } from '../../../../../api/rankings';

import { DataType } from './columnsConfig';

export const getRowClassName = (username: string) => (record: DataType) => {
  // class determining the active user
  const activeUserClass = username === record.name ? 'ant-table-row-selected' : '';

  // class determining the color of the badge
  const rankClass = record.points > 0 ? `rank-${record.rank}` : '';

  return activeUserClass + ' ' + rankClass;
};

export const mapRankingTableData = (user: Results) => {
  return {
    key: user.rank + user.user,
    rank: user.rank,
    name: user.user,
    points: user.points || 0,
    avatar: user?.avatar,
    teamName: user?.team?.name,
    teamImage: user?.team?.image,
    userId: user?.user_id,
  };
};
