import React from 'react';

import CardTitle from 'components/atoms/CardTitle';
import ModeSwitch, { viewMode } from 'components/atoms/ModeSwtich';
import { DataNavigation } from 'components/molecules/DataNavigation';
import GroupDataNavigation from 'components/molecules/GroupDataNavigation/GroupDataNavigation';
import { ContentCard } from 'components/templates/DashboardLayout/styles';

import { GeneralLeagueBet } from './GeneralLeagueBet';
import { GroupLeagueBet } from './GroupLeagueBet';

function Bet() {
  const config = {
    [viewMode.general]: (
      <ContentCard
        data-testid={'betTabs'}
        title={<CardTitle>Typuj Generalnie</CardTitle>}
      >
        <DataNavigation ContentComponent={GeneralLeagueBet} />
      </ContentCard>
    ),
    [viewMode.groups]: (
      <ContentCard data-testid={'betTabs'} title={<CardTitle>Typuj Grupy</CardTitle>}>
        <GroupDataNavigation ContentComponent={GroupLeagueBet} />
      </ContentCard>
    ),
  };

  return <ModeSwitch componentConfig={config} />;
}

export default Bet;
