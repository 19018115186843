import { ROUTES } from 'constants/routes';

import React from 'react';

import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  gap: 8px;
`;

interface NoLeaguesProps {
  hasButton?: boolean;
}

const NoGroups: React.FC<NoLeaguesProps> = ({ hasButton = true }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Paragraph>Obecnie nie jesteś członkiem, żadnej grupy</Paragraph>
      {hasButton && (
        <Button size="large" type="primary" onClick={() => navigate(ROUTES.GROUPS)}>
          Grupy
        </Button>
      )}
    </Wrapper>
  );
};

export default NoGroups;
